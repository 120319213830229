<template>
  <div class="bg-white-900 h-full">
    <div class="px-0 mx-auto relative h-full flex">
      <auth-info class="flex-shrink-0" />
      <base-logo class="fixed top-4 left-8" />
      <auth-sign-in-form />
    </div>
  </div>
</template>

<script>
import BaseLogo from "@/components/base/BaseLogo.vue";
import AuthInfo from "@/components/auth/AuthInfo.vue";
import AuthSignInForm from "@/components/auth/AuthSignInForm.vue";

export default {
  components: {
    "base-logo": BaseLogo,
    "auth-info": AuthInfo,
    "auth-sign-in-form": AuthSignInForm,
  },
};
</script>
