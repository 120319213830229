<template>
  <div class="flex flex-grow justify-center items-center flex-col pl-500px">
    <div class="max-w-400 w-full mx-auto">
      <h2 class="text-4xl font-medium text-black-900 mb-8">Sign in</h2>
      <t-alert variant="danger" :show="authError.length > 0" class="my-4">
        {{ authError }}
      </t-alert>
      <validation-observer
        tag="form"
        ref="form"
        @submit.prevent="handleSubmit"
        class="space-y-6 mb-11"
      >
        <utils-input
          :validationRules="'required|email'"
          :inputName="'Email'"
          :inputType="'text'"
          :isSubmitted="isSubmitted"
          v-model="data.username"
        />

        <utils-input
          :validationRules="'required'"
          :inputName="'Password'"
          :inputType="'password'"
          :isSubmitted="isSubmitted"
          v-model="data.password"
        >
          <router-link
            to="/forgot-password"
            class="text-black-900 text-sm hover:text-orange-900"
          >
            Forgot password?
          </router-link>
        </utils-input>

        <base-button colorScheme="orange" class="mt-8 py-3 w-full">
          <span class="font-normal text-sm"> Sign in</span>
        </base-button>
      </validation-observer>
      <p class="text-black-900 text-sm">
        Don't have an account yet?
        <router-link
          :to="{ name: 'joinUs' }"
          class="text-orange-900 hover:text-grey-700"
        >
          Join us
        </router-link>
      </p>
      <p class="text-black-900 text-sm mt-2">
        Want to open admin dashboard?
        <a href="/admin" class="text-orange-900 hover:text-grey-700">
          Click here
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";

import BaseButton from "@/components/utils/Button.vue";
import UtilsInput from "@/components/utils/UtilsInput.vue";
import { LOGIN } from "@/store//actions.type";

export default {
  name: "AuthSignInForm",
  components: {
    "utils-input": UtilsInput,
    "base-button": BaseButton,
    ValidationObserver,
  },
  data() {
    return {
      isSubmitted: false,
      data: {
        username: "",
        password: "",
      },
    };
  },
  computed: {
    ...mapGetters(["authError", "isAuthenticated"]),
  },
  methods: {
    handleSubmit() {
      this.isSubmitted = true;

      this.$refs.form.validate().then(async (valid) => {
        if (!valid) {
          return;
        }

        await this.$store.dispatch(LOGIN, this.data);

        if (this.isAuthenticated) {
          this.$router.push(this.$route.query.redirect || "/");
        }
      });
    },
  },
};
</script>
